// extracted by mini-css-extract-plugin
export var decor = "card-module--decor--rH6r3";
export var decor1 = "card-module--decor1--4BIHj";
export var decor2 = "card-module--decor2--chp5Z";
export var glow = "card-module--glow--GTgbW";
export var image = "card-module--image--TjleE";
export var link = "card-module--link--oFvrv";
export var main = "card-module--main--EKrN6";
export var neon = "card-module--neon--A1v0B";
export var neonLeft = "card-module--neonLeft--vNylu";
export var neonRight = "card-module--neonRight--CKaSS";
export var rive = "card-module--rive--PawgP";
export var riveOrImageHolder = "card-module--riveOrImageHolder--SB-lR";